import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServicesRoutingModule } from './services-routing.module';
import { ServicesPageComponent } from './services-page/services-page.component';

@NgModule({
	declarations: [ServicesPageComponent],
	imports: [CommonModule, ServicesRoutingModule],
})
export class ServicesModule {}
